import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import moment from 'moment';

// Services
import OnlineBookingService from 'services/OnlineBookingService';
import GlobalStateService from 'services/GlobalStateService';
import packageJson from '../package.json';

// Components
import Layout from 'components/layout/Layout';
import BookingPage from 'components/pages/booking/BookingPage';
import ConfirmApptPage from 'components/pages/ConfirmApptPage';
import FormPage from 'components/pages/FormPage';
import SalonListPage from 'components/pages/SalonListPage';
import Loader from 'components/reusable/Loader';
import AccountDashboard from './components/pages/AccountDashboard';
import Unsubscribe from './components/pages/Unsubscribe';
window.moment = moment;

//-----------------------------------------------------------------------------------

export default class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        // Reload if needed
        // https://stackoverflow.com/questions/51207570/how-to-clear-browser-cache-in-reactjs
        const version = localStorage.getItem('version');
        if (version != packageJson.version) {
            if ('caches' in window) {
                // Delete all the cache files
                caches.keys().then((names) => {
                    names.forEach(name => {
                        caches.delete(name);
                    })
                });
                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload(true);
            }
            localStorage.setItem('version', packageJson.version);
        }

        // Determine salon code or identify if we're on a special page instead
        const url = window.location;
        const specialPageOrSalonCode = url.pathname.length > 0 ? (url.pathname.split('/')[1] || '').toLowerCase() : '';
        switch (specialPageOrSalonCode) {
            case 'confirm':
                this.state.specialPage = specialPageOrSalonCode;
                this.loadConfirmApptInfo();
                break;
            case 'group':
                const groupCode = url.pathname.length > 1 ? (url.pathname.split('/')[2] || '') : '';
                GlobalStateService.setValue('salonGroupCode', groupCode);
                GlobalStateService.setValue('salonCode', specialPageOrSalonCode);
                this.loadSalonList();
                break;
            default:
                GlobalStateService.setValue('salonGroupCode', '');
                GlobalStateService.setValue('salonCode', specialPageOrSalonCode);
                this.loadSalonInfo();
                break;
        }

        // Set up interval to post height in case we're in an iframe
        setInterval(() => {
            const height = document.body.scrollHeight;
            window.parent.postMessage(height + 'px', '*');
        }, 100);
    }

    async loadSalonList() {
        const salonInfo = await OnlineBookingService.getSalonInfo();
        this.setState({
            isLoading: false,
            salonInfo
        }, () => {
            if (salonInfo) {
                this.loadCustomStylesheet();
            }
        });
    }

    async loadSalonInfo() {
        const salonInfo = await OnlineBookingService.getSalonInfo();
        this.setState({
            isLoading: false,
            salonInfo
        }, () => {
            if (salonInfo) {
                document.title = salonInfo.name;
                this.loadCustomStylesheet();
            } else {
                document.title = 'Online booking';
            }
        });
    }

    loadCustomStylesheet() {
        const salonInfo = this.state.salonInfo;
        let css = `
            .button.primary {
                background-color: ${salonInfo.colour1Back}; 
                color: ${salonInfo.colour1Fore}
            }
                .button.primary:hover {
                    background-color: ${salonInfo.colour2Back}; 
                    color: ${salonInfo.colour2Fore}
                }
            .button.secondary,
            .date-selector-date.selected {
                background-color: ${salonInfo.colour2Back};
                color: ${salonInfo.colour2Fore}
            }
                .button.secondary:hover {
                    background-color: ${salonInfo.colour1Back}; 
                    color: ${salonInfo.colour1Fore}
                }
            .panel-header {
               background-color: ${salonInfo.colour3Back}; 
               color: ${salonInfo.colour3Fore}
            }
            .hollow-button {
                border-color: ${salonInfo.colour2Back} !important;
                color: inherit;
            }
                .hollow-button:hover,
                .hollow-button.selected {
                    border-color: transparent;
                    background-color: ${salonInfo.colour2Back};
                    color: #ffffff;
                }

            .checkbox {
                border-color: ${salonInfo.colour2Back};
            }
                .service-heading:hover .checkbox:not(.checked)::after,
                .stylist:hover .checkbox:not(.checked)::after {
                    background-color: ${salonInfo.colour2Back};
                    opacity: 0.2:
                }
                .checkbox.checked::after {
                    background-color: ${salonInfo.colour2Back};
                }

            ::-webkit-scrollbar {
                width: 10px;
                height: 10px;
            }

            ::-webkit-scrollbar-button {
                width: 8px;
                height: 8px;
            }

            ::-webkit-scrollbar-thumb {
                background: ${salonInfo.colour2Back};
                border: 0px none #ffffff;
                border-radius: 50px;
            }

            ::-webkit-scrollbar-thumb:hover,
            ::-webkit-scrollbar-thumb:active {
                background: ${salonInfo.colour2Back};
            }

            ::-webkit-scrollbar-track {
                background: #eaeaea;
                border: 0px none #ffffff;
                border-radius: 50px;
            }

            ::-webkit-scrollbar-track:hover,
            ::-webkit-scrollbar-track:active {
                background: #eaeaea;
            }

            ::-webkit-scrollbar-corner {
                background: transparent;
            }

            .steps-panel ul li.completed {
	            border-bottom-color: ${salonInfo.colour2Back};
            }

            .date-picker .react-datepicker__current-month {
	            background: ${salonInfo.colour2Back};
            }

            .slot-picker .inner  .header {
	            background-color: ${salonInfo.colour2Back};
            }

            .date-picker .react-datepicker__day--selected,
            .date-picker .react-datepicker__month-text--keyboard-selected,
            .date-picker .react-datepicker__quarter-text--keyboard-selected,
            .date-picker .react-datepicker__year-text--keyboard-selected {
                color: ${salonInfo.colour1Fore} !important;
                background-color: ${salonInfo.colour1Back} !important;
            }

            .please-enter-card-details,
            .agree-terms-conditions a
            {
                color: ${salonInfo.colour2Back} !important;
            }
        `;
        switch (salonInfo.backgroundStyle) {
            case 'transparent':
                document.body.classList.add('transparent-background');
                break;
            case 'solidColour':
                document.body.style.backgroundColor = salonInfo.backgroundSetting;
                break;
            case 'url':
                document.body.classList.add('custom-background');
                document.body.style.backgroundImage = `url(${salonInfo.backgroundSetting})`;
                break;
            case 'upload':
                document.body.classList.add('custom-background');
                document.body.style.backgroundImage = `url(${salonInfo.baseURL}/api/upload/view/${salonInfo.backgroundSetting})`;
                break;
            default:
                document.body.classList.add('default-background');
                break;
        }
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        head.appendChild(style);
        style.type = 'text/css';
        if (style.styleSheet) { // IE8 and below.
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
    }

    async loadConfirmApptInfo() {
        // Get appt confirm code from URL
        let code;
        if (window.location.pathname.length > 0) {
            const urlSplit = window.location.pathname.split('/');
            if (urlSplit.length >= 2) {
                code = (urlSplit[2] || '').toLowerCase();
            }
        }

        // Load confirm appt info
        let confirmApptInfo;
        if (code) {
            confirmApptInfo = await OnlineBookingService.getConfirmInfo(code);
            if (confirmApptInfo) {
                GlobalStateService.setValue('salonCode', confirmApptInfo.salonCode);
                this.loadSalonInfo();
            } else {
                this.setState({ isLoading: false });
            }
        } else {
            this.setState({ isLoading: false });
        }

        // Update UI
        this.setState({ confirmApptInfo });
    }

    markAsConfirmed() {
        const confirmApptInfo = { ...this.state.confirmApptInfo };
        confirmApptInfo.isConfirmed = true;
        this.setState({ confirmApptInfo });
    }

    render() {
        const {
            isLoading,
            specialPage,
            salonInfo,
            confirmApptInfo
        } = this.state;
        const salonCode = GlobalStateService.getValue('salonCode');
        const salonGroupCode = GlobalStateService.getValue('salonGroupCode');

        // Render special page instead
        switch (specialPage) {
            case 'confirm':
                return (
                    <Layout>
                        {isLoading ?
                            <Loader /> :
                            <ConfirmApptPage
                                salonInfo={salonInfo}
                                confirmApptInfo={confirmApptInfo}
                                onConfirm={() => this.markAsConfirmed()}
                            />
                        }
                    </Layout>
                );
        }

        // Make sure a salon code is provided
        if (!salonCode) {
            return (
                <div className="panel floating-panel">
                    <div className="panel-body">
                        No Salon Code Provided
                    </div>
                </div>
            );
        }
        else if (salonCode == 'group' && !salonGroupCode) {
            return (
                <div className="panel floating-panel">
                    <div className="panel-body">
                        No Group Salon Code Provided
                    </div>
                </div>
            );
        }

        // Loading
        if (isLoading) {
            return (
                <div className="please-wait">
                    <Loader />
                </div>
            );
        }

        // Online booking not enabled
        if (salonGroupCode) {
            return (
                <Layout>
                    <SalonListPage />
                </Layout>
            )
        } else {
            if (!salonInfo) {
                return (
                    <div className="panel floating-panel">
                        <div className="panel-body">
                            Sorry, this salon is not allowing online bookings at the moment.
                        </div>
                    </div>
                );
            }
        }

        // Render
        return (
            <Layout>
                <Switch>

                    <Route path="/form/:formType?/:customer?" render={(props) =>
                        <FormPage
                            {...props}
                            salonCode={salonCode}
                            salonInfo={salonInfo}
                        />}
                    />
                    <Route path="/account/:subPage?/:subPageParam?" render={(props) =>
                        <AccountDashboard
                            {...props}
                            salonCode={salonCode}
                            salonInfo={salonInfo}
                        />}
                    />
                    <Route path="/unsubscribe/:guid?" render={(props) =>
                        <Unsubscribe
                            {...props}
                        />}
                    />
                    <Route path="/:subPage?/:subPageParam?" render={(props) =>
                        <BookingPage
                            {...props}
                            salonCode={salonCode}
                            salonInfo={salonInfo}
                        />}
                    />                   
                </Switch>
            </Layout>
        );
    }

}
